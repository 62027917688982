import React from 'react'
import myphoto from '../../images/profile.jpg'
import style from './profilephoto.module.scss'


const ProfilePhoto = () => (
    <div className={style.ProfilePhoto}>
        <img className={style.photo} src={myphoto} alt="" />
    </div>
)

export default ProfilePhoto