import React from "react";
import ProfilePhoto from "../components/profilePhoto/ProfilePhoto";
import Layout from "../components/layout";
import SEO from "../components/seo";

const About = () => (
  <Layout>
    <SEO title="About | Alfredo Riveroll" />

    <div className="wrapper ComponentSeparation">
      <ProfilePhoto />
      <h1
        className="t2"
        style={{
          marginBottom: "1rem",
          marginTop: "1rem"
        }}
      >
        Alfredo Riveroll
      </h1>
      <br />

      <p>Living in Tokyo since 2015.</p>
      <br />

      <div className="ComponentSeparation">
        <p>
          I have spent the last four years developing my skills as a Front End
          Developer.
        </p>
        <br />
        <p>
          In my previous work place, I received the award of “Most Improved
          Employee” in 2017 and I was promoted twice.
        </p>
        <br />
        <p>
          I am also the founder of Mipon, the most popular anime website in the
          Anime Tourism's industry. With less than a year, Mipon has more than
          60,000 views per month.
        </p>
        <br />

        <p>
          <strong>
            I love managing teams, crafting fast websites and solving problems.
          </strong>
        </p>
      </div>

      <p>When I am not coding I'm usually:</p>
      <ul>
        <li>・Watching Sports and Anime.</li>
        <li>
          ・Working on my personal project
          <a
            className="highlight"
            href="https://mipon.org"
            rel="nofollow noopener noreferrer"
            target="_blank"
            style={{ marginLeft: "6px" }}
          >
            Mipon
          </a>
          .
        </li>
        <li>・Learning a new technology.</li>
        <li>・Hanging out with my girlfriend and friends.</li>
        <li>
          ・Reading books about Marketing, Business or Psychology (I read at
          least 20 books per year).
        </li>
      </ul>
      <p />
    </div>
  </Layout>
);

export default About;
